import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC } from 'react';
import { AddressProps } from '../model';

const Address: FC<AddressProps> = ({
  address: [
    {
      properties: { title, data },
    },
  ],
}) => (
  <div className="gs-footer-top__address">
    <DangerouslySetInnerHtml className="gs-footer-top__title" element="p" html={title} />
    <div className="gs-footer-top__address-data">
      <DangerouslySetInnerHtml html={data} />
    </div>
  </div>
);

export default Address;
