import React, { FC, ReactElement } from 'react';
import Container from 'react-bootstrap/Container';

import ExternalLink from 'components/common/ExternalLink';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IBottomFooter } from './model';
import './BottomFooter.scss';
import './BottomFooterOverride.scss';

const BottomFooter: FC<IBottomFooter> = ({ links, copyright }): ReactElement => (
  <div className="gs-footer-bottom">
    <Container fluid>
      {links?.length > 0 ? (
        <div className="gs-footer-bottom__links">
          <ul>
            {links.map(({ properties: { link: [{ name, url, target }] } }) => (
              <li key={name}>
                <ExternalLink to={url} target={target}>
                  {name}
                </ExternalLink>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {copyright ? (
        <DangerouslySetInnerHtml className="gs-footer-bottom__copyright" html={copyright} />
      ) : null}
    </Container>
  </div>
);

export default BottomFooter;
