import React, { FC, useState } from 'react';
import { CSSTransition, Transition } from 'react-transition-group';

import HeaderLogo from 'components/Header/HeaderLogo';
import Navigation from 'components/Header/Navigation';
import BurgerNavigation from 'components/Header/BurgerNavigation';
import SearchForm from 'components/Header/SearchForm';
import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';
import AdditionalSeoAttributes from 'components/Header/AdditionalSeoAttributes';
import LanguageSelector from 'components/common/LanguageSelector';
import { parseBoolean } from 'utils/parseHelpers';

import { CommonPageContent } from '../../../@types/common';

import './Header.scss';
import './HeaderOverride.scss';

const Header: FC<CommonPageContent.IHeader> = React.memo(
  ({
    logo,
    logoAdditionalText,
    link,
    navigation,
    ariaBurger,
    mobileNavigation,
    ariaSearch,
    ariaSearchSubmit,
    ariaSearchInput,
    ariaClear,
    searchPlaceholder,
    lsSettings,
    isGaviHeader,
    disableSearchFunctionality,
  }) => {
    const [isFormVisible, setFormVisibiliti] = useState(false);
    const handleSearchClick = () => {
      setFormVisibiliti(!isFormVisible);
    };
    const defaultStyle = {
      transition: 'opacity 300ms',
      opacity: 0,
    };

    const transitionStyles = {
      entering: { opacity: 1 },
      entered: { opacity: 1 },
      exiting: { opacity: 0 },
      exited: { opacity: 0 },
    };

    return (
      <header className="gs-header">
        <AdditionalSeoAttributes />
        <div className="gs-header__container">
          <HeaderLogo {...{ logo, link, logoAdditionalText }} />
          {!isGaviHeader ? <Navigation navigation={navigation} /> : null}
          {parseBoolean(lsSettings.inHeader) ? (
            <LanguageSelector type="drop" {...lsSettings} />
          ) : null}

          {!isGaviHeader ? (
            <>
              {disableSearchFunctionality ? null : (
                <CSSTransition
                  in={isFormVisible}
                  timeout={{ enter: 300, exit: 0 }}
                  classNames="gs-header__button"
                >
                  <Button
                    variant="icon"
                    classes="gs-header__search-btn"
                    ariaLabel={ariaSearch}
                    clickHandler={handleSearchClick}
                  >
                    <IconCustom icon="search" />
                  </Button>
                </CSSTransition>
              )}

              <BurgerNavigation
                isFormVisible={isFormVisible}
                ariaBurger={ariaBurger}
                navigation={mobileNavigation}
                lsSettings={lsSettings}
              />

              <Transition
                in={isFormVisible}
                mountOnEnter
                unmountOnExit
                timeout={300}
                classNames="gs-header__form"
              >
                {(state) => (
                  <div
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    <SearchForm
                      ariaSearch={ariaSearch}
                      ariaSearchInput={ariaSearchInput}
                      ariaSearchSubmit={ariaSearchSubmit}
                      ariaClear={ariaClear}
                      onClose={handleSearchClick}
                      placeholder={searchPlaceholder}
                    />
                  </div>
                )}
              </Transition>
            </>
          ) : null}
        </div>
      </header>
    );
  }
);

export default Header;
