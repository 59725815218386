import React, { FC, useEffect, useState, useRef } from 'react';
import NavigationItem from './NavigationItem';
import Dropdown from './Dropdown';
import { INavigation } from './model';
import './Navigation.scss';
import './NavigationOverride.scss';

const Navigation: FC<INavigation> = React.memo(({ navigation }) => {
  const [currentItem, setCurrentItem] = useState('');
  useEffect(() => {
    const restoreActiveMenu = (e) => {
      if (e.keyCode === 27 && currentItem.length) setCurrentItem('');
    };
    document.addEventListener('keydown', restoreActiveMenu);

    return () => {
      document.removeEventListener('keydown', restoreActiveMenu);
    };
  });

  const navigationRef = useRef<HTMLUListElement>(null);

  const handleBlur = ({ relatedTarget }: React.FocusEvent) => {
    const isNavigationItem = navigationRef.current?.contains(relatedTarget as HTMLElement);

    if (!isNavigationItem && currentItem) {
      setCurrentItem('');
    }
  };

  return (
    <nav className="gs-navigation">
      {navigation?.length > 0 ? (
        <ul className="gs-navigation__list" ref={navigationRef}>
          {navigation.map((navItem) => (
            <NavigationItem
              key={navItem.properties.label}
              {...{ currentItem, setCurrentItem, handleBlur, Dropdown, ...navItem }}
              classes="gs-navigation__list-item"
            />
          ))}
        </ul>
      ) : null}
    </nav>
  );
});

export default Navigation;
