import React, { FC, useState, useEffect } from 'react';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import LanguageSelector from 'components/common/LanguageSelector';
import { parseBoolean } from 'utils/parseHelpers';
import Links from './Links';

import { IBurgerNavigation } from './model';
import './BurgerNavigation.scss';
import './BurgerNavigationOverride.scss';

const BurgerNavigation: FC<IBurgerNavigation> = ({
  navigation,
  ariaBurger,
  isFormVisible,
  lsSettings,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1220px)');

    const screenWidthHandler = () => {
      if (mediaQuery.matches) {
        setIsOpen(false);
      }
    };

    mediaQuery.addEventListener('change', screenWidthHandler);

    return () => {
      mediaQuery.removeEventListener('change', screenWidthHandler);
    };
  }, []);

  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (isOpen) {
      bodyElement?.classList.add('no-scroll');
    } else {
      bodyElement?.classList.remove('no-scroll');
    }

    return () => {
      bodyElement?.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <nav className="gs-burger-navigation">
      <Button
        variant="icon"
        clickHandler={handleClick}
        classes="gs-burger-navigation__button"
        ariaLabel={ariaBurger}
      >
        <IconCustom icon={isOpen ? 'close' : 'menu'} />
      </Button>
      {isOpen ? (
        <div
          className="gs-burger-navigation__panel"
          style={{ paddingTop: isFormVisible ? '60px' : '' }}
        >
          {navigation.map(({ properties: { sectionLabel, links } }) => (
            <div key={sectionLabel} className="gs-burger-navigation__section">
              {sectionLabel ? (
                <DangerouslySetInnerHtml
                  element="span"
                  className="gs-burger-navigation__title"
                  html={sectionLabel}
                />
              ) : null}
              {links.length ? <Links {...{ links }} /> : null}
            </div>
          ))}
          {parseBoolean(lsSettings.inBurger) ? (
            <LanguageSelector type="list" {...lsSettings} />
          ) : null}
        </div>
      ) : null}
    </nav>
  );
};

export default BurgerNavigation;
