import React, { FC, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import classnames from 'classnames';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { INavigationItem } from './model';

const NavigationItem: FC<INavigationItem> = ({
  properties: { link, label, subNavigation },
  currentItem,
  setCurrentItem,
  handleBlur,
  classes,
  Dropdown,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigationItemCssClassNames = classnames({
    open: isOpen,
    active: isActive,
    [classes]: classes,
  });

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      if (!isOpen) {
        setIsOpen(true);
        setCurrentItem(label);
      } else {
        setIsOpen(false);
        setCurrentItem('');
      }
    }
  };

  const handleFocus = () => {
    setCurrentItem(label);
  };
  const location = useLocation();

  useEffect(() => {
    const activeLinks =
      subNavigation?.some(
        (item) => item?.properties?.link?.[0] && location.href.includes(item.properties.link[0].url)
      ) ||
      location.href.includes(link?.[0]?.url) ||
      false;
    setIsActive(activeLinks);
  }, []);

  useEffect(() => {
    if (currentItem !== label) setIsOpen(false);
  }, [currentItem]);

  return (
    <li key={label} className={navigationItemCssClassNames}>
      <Button
        variant="link"
        activeClassName="active"
        onKeyDown={handleKeyPressed}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...(link?.[0]?.url ? { to: link?.[0]?.url } : {})}
      >
        <DangerouslySetInnerHtml html={label} />
        {subNavigation?.length ? <IconCustom icon="chevron-down" /> : null}
      </Button>
      {subNavigation?.length ? <Dropdown {...{ subNavigation, handleBlur }} /> : null}
    </li>
  );
};

export default NavigationItem;
