import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';

import { isBrowser } from 'utils/browser';
import { IBurgerLinks } from './model';

const Links: FC<IBurgerLinks> = ({ links }) => {
  const location = useLocation();
  const needReloadCheck = useCallback(
    (url) => () => {
      isBrowser() && location.pathname === url && window.location.reload();
    },
    []
  );

  return (
    <ul className="gs-burger-navigation__links">
      {links.map(({ properties: { link, label } }) => (
        <li key={label} className="gs-burger-navigation__links-item">
          <Link
            onClick={needReloadCheck(link?.[0]?.url)}
            to={link[0].url}
            partiallyActive
            activeClassName="active"
          >
            <DangerouslySetInnerHtml element="span" html={label} />
            <IconCustom icon="chevron-right" />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Links;
