import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC } from 'react';
import { EmailProps } from '../model';

const EmailSection: FC<EmailProps> = ({
  emailSection: [
    {
      properties: { title, email },
    },
  ],
}) => (
  <div className="gs-footer-top__email-section">
    <DangerouslySetInnerHtml className="gs-footer-top__title" element="p" html={title} />
    <div className="gs-footer-top__email-section-data">
      <DangerouslySetInnerHtml html={email} />
    </div>
  </div>
);

export default EmailSection;
