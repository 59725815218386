import React, { FC } from 'react';

import TopFooter from './TopFooter';
import BottomFooter from './BottomFooter';
import MiddleFooter from './MiddleFooter';

import './Footer.scss';
import { CommonPageContent } from '../../../@types/common';

const Footer: FC<CommonPageContent.IFooter> = ({
  logo,
  support,
  address,
  emailSection,
  navigation,
  bottomLinks,
  copyright,
  middleCopyright,
  sponsorsTitle,
  sponsors,
  image,
  lsSettings,
  footerNumber,
}) => {
  const hasBottomFooter = bottomLinks?.length || copyright;

  return (
    <div className="gs-footer">
      <TopFooter
        {...{ logo, support, address, navigation, lsSettings, emailSection, footerNumber }}
      />
      <MiddleFooter {...{ middleCopyright, sponsorsTitle, sponsors, image }} />
      {hasBottomFooter ? <BottomFooter links={bottomLinks} copyright={copyright} /> : null}
    </div>
  );
};

export default Footer;
